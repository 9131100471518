<template>
  <div
    class="content d-flex flex-column justify-content-center align-items-center mx-auto m-5"
  >
    <div
      class="m-3 attraction-carousel d-flex justify-content-center align-items-center"
      v-touch:swipe="onTouch"
    >
      <transition name="fade" mode="out-in">
        <GalleryCarousel
          class="p-sm-2"
          @back="back"
          @next="next"
          :media="media"
          :key="currentMedia"
        ></GalleryCarousel>
      </transition>
    </div>
    <div class=" flex-column flex-sm-row pb-4">
      <i v-for="image in attraction.medias" :key='image.id' @click="imgSelect(image.id)" class="carousel-nav"></i>
    </div>
    <div class="attraction-infos container my-sm-2">
      <div class="row">
        <div class="col content-infos">
          <div>
            <button class="content-return my-2" name="" @click="backHistory">
              <i class="fa fa-arrow-left"></i>
            </button>
          </div>
          <h2 v-if="lang === 'ar'" class="content-infos-title my-2 py-2 text-right">
            {{ attraction.name }}
          </h2>
          <h2 v-else class="content-infos-title my-2 py-2">
            {{ attraction.name }}
          </h2>
          <div class="content-infos-description">
            <h3 v-if="lang === 'ar'" class="my-3 text-right">: وصف</h3>
            <h3 v-else class="my-3">Description:</h3>
            <p v-if="lang === 'ar'" class="text-right">
              {{ attraction.description }}
            </p>
            <p v-else>
              {{ attraction.description }}
            </p>
          </div>
        </div>
        <div class="col container">
          <div class="row">
            <div id="rules" class="p-3 rules-title" v-if="attraction.rules">
              <h3 v-if="lang === 'fr'">Règles de sécurité:</h3>
              <h3 v-else-if="lang === 'ar'" class="text-right">: قواعد السلامة</h3>
              <h3 v-else>Safety rules:</h3>
              <div class="p-3 content-infos-rules-ctn">
                <rule
                  v-for="rule in attraction.rules"
                  :key="rule.id"
                  :rule="rule"
                ></rule>
                <div
                  class="content-infos-the-rule-ctn p-4 d-flex justify-content-center align-items-center text-center"
                >
                  {{ addRule }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="row p-4 content-infos-sensations-ctn d-flex justify-content-center align-items-center"
          >
            <div
              class="d-flex flex-column justify-content-center align-items-center m-1"
              v-for="stat in attraction.stats"
              :key="stat.id"
            >
              <ve-progress
                class="mb-1"
                :progress="stat.value"
                :color="stat.color"
                :size="150"
                :thickness="20"
                fontsize="4rem"
                emptyColor="#11111121"
              />
              <h3 class="text-center">{{ stat.name }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GalleryCarousel from "@/components/GalleryCarousel/GalleryCarousel.vue";
import Rule from "@/components/Rule/Rule.vue";
export default {
  components: {
    GalleryCarousel,
    Rule,
  },
  data() {
    return {
      currentMedia: 0,
    };
  },
  computed: {
    attraction() {
      return this.$store.getters['attractions/getAttractions'][this.$route.params.id];
    },
    addRule() {
      return this.$store.getters['attractions/getAddRule'];
    },
    lang() {
      return this.$store.getters['lang/getCurrentLang'];
    },
    media() {
      return {
        id: this.attraction.id,
        maxMediaNum: this.attraction.medias.length - 1,
        type: this.attraction.medias[this.currentMedia].type,
        url: this.attraction.medias[this.currentMedia].url,
        title: "",
        description: this.attraction.medias[this.currentMedia].description,
      };
    },
  },
  methods: {
    back() {
      let dots = document.getElementsByClassName("carousel-nav");

      if (this.currentMedia - 1 < 0) {
        dots[this.currentMedia].classList.remove("carousel-nav-active");
        this.currentMedia = this.media.maxMediaNum;
        dots[this.currentMedia].classList.add("carousel-nav-active");
      } else {
        dots[this.currentMedia].classList.remove("carousel-nav-active");
        this.currentMedia -= 1;
        dots[this.currentMedia].classList.add("carousel-nav-active");
      }
    },
    next() {
      let dots = document.getElementsByClassName("carousel-nav");

      if (this.currentMedia + 1 > this.media.maxMediaNum) {
        dots[this.currentMedia].classList.remove("carousel-nav-active");
        this.currentMedia = 0;
        dots[this.currentMedia].classList.add("carousel-nav-active");
      } else {
        dots[this.currentMedia].classList.remove("carousel-nav-active");
        this.currentMedia += 1;
        dots[this.currentMedia].classList.add("carousel-nav-active");
      }
    },
    backHistory() {
      if (this.$router.currentRoute.value.name === "attraction") {
        this.$router.replace({ name: "attractions" });
      } else if (this.$router.currentRoute.value.name === "service") {
        this.$router.replace({ name: "services" });
      } else {
        this.$router.back();
      }
    },
    onTouch(e) {
      switch (e) {
        case "left":
          this.next();
          break;
        case "right":
          this.back();
          break;
        default:
          break;
      }
    },
    imgSelect(n) {
      let dots = document.getElementsByClassName("carousel-nav");

      dots[this.currentMedia].classList.remove("carousel-nav-active");
      this.currentMedia = n;
      dots[this.currentMedia].classList.add("carousel-nav-active");
    },
  },
  mounted() {
    let dots = document.getElementsByClassName("carousel-nav");

    dots[this.currentMedia].classList.add("carousel-nav-active");
  }
};
</script>

<style src="./AttractionPage.css" scoped></style>
