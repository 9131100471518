<template lang="">
  <router-link :to="{ name: 'attraction', params: { id: attraction.id } }">
    <div
      class="horizontal-card"
      :style="{ background: `center/cover url(${attraction.medias[0].url})` }"
    >
      <div class="horizontal-card-text text-center">
        <h1 class="horizontal-card-text-title">{{ attraction.name }}</h1>
        <p class="horizontal-card-text-description d-none d-sm-block">
          {{ attraction.brief }}
        </p>
      </div>
    </div>
  </router-link>
</template>
<script>
export default {
  props: {
    attraction: Object,
  },
  data() {
    return {};
  },
};
</script>
<style src="./HorizontalCard.css" scoped></style>
