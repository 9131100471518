<template lang="">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <img
      :key="rule.id"
      class="content-infos-rules-img m-2"
      :src="rule.imgUrl"
    />
    <h5 class="rules-desc">{{ rule.desc }}</h5>
  </div>
</template>
<script>
export default {
  props: {
    rule: Object,
  },
  methods: {
    onclick() {
      document.getElementById("rules").addEventListener("contextmenu", function(e){
          e.preventDefault();
        }, false);
      document.getElementById("rules").addEventListener("dragstart", function(e){
          e.preventDefault();
        }, false);
    },
  },
  mounted() {
    this.onclick();
  },
};
</script>
<style src="./Rule.css" scoped></style>
