<template>
  <div>
    <transition-group :appear="true" name="pullright">
      <horizontalCard
        v-for="attraction in attractions"
        :key="attraction.id"
        :attraction="attraction"
      ></horizontalCard>
    </transition-group>
  </div>
</template>

<script>
import HorizontalCard from '@/components/HorizontalCard/HorizontalCard.vue';
export default {
  components: {
    HorizontalCard,
  },
  computed: {
    attractions() {
      return this.$store.getters['attractions/getAttractions'];
    },
  },
};
</script>

<style src="./Attractions.css" scoped></style>
